<template>
</template>

<script>
export default {
    data () {
      return {
        intervalId : null,
        compteur: 0,
        alerts:[],
        user: {id:0},
        desserts: [],
        dataw: '',
      }
    },
    mounted(){
        this.UpdateDataTable();
    },
    beforeDestroy(){
        clearInterval(this.intervalId);
    },
    methods:{
        async UpdateDataTable(){
            const userOk = await Promise.all([this.getTagAvailable()])
            if (userOk) {
                const alerts = await Promise.all([this.getAlerts()]);
                if (alerts) {
                    const alertsSetup = await Promise.all([this.SetUpAlerts()]);
                    if(alertsSetup){
                        this.intervalId = setInterval(this.GetData, 2000);
                    }
                }
            } 
        },
        async getTagAvailable(){
            this.user = JSON.parse(localStorage.getItem('user'))
        },
        async getAlerts(){
            const user = encodeURIComponent(this.user.id);
            const url = this.$api.getRESTApiUri() + `/user-alert/${user}`;
            return fetch(url)
            .then(res => res.text())
            .then((result) => {
                const data = JSON.parse(result);
                this.alerts = data.alertes;
            })
            .catch((error) => {
                console.log(error)
            });
        },

        SetUpAlerts(){
          for(let i = 0; i < this.alerts.length; i++){
            const char = this.alerts[i].split('/');
            const tag = char[0];
            const data = char[1];
            const signe = char[2];
            let value = char[3];
            if(data == 'temperature'){
                value = value*100
            }
            if(data == 'adc_v'){
                value = value*1000
            }
            this.desserts.push(
              {
              id :i,
              name: tag,
              data: data,
              signe: signe,
              value: value,
            })
          }
        },

        async GetData() {
            for (let i = 0; i < this.desserts.length; i++) {
                const tag = encodeURIComponent(this.desserts[i].name);
                const data = encodeURIComponent(this.desserts[i].data);
                this.dataw = this.desserts[i].data;
                const url = this.$api.getRESTApiUri() + `/get-data/alertes/${tag}/${data}`;
                try {
                const res = await fetch(url);
                const result = await res.text();
                const data = JSON.parse(result);
                for (const key in data[0]) {
                    const value = data[0][key];
                    const value_compared = this.desserts[i].value;
                    // console.log(value);
                    // console.log(value_compared);
                    // console.log(this.desserts[i].signe);
                    if (this.desserts[i].signe == "<") {
                    if (value < value_compared) {
                        this.$toasted.show(
                        this.desserts[i].data + " is < to " + value_compared,
                        {
                            theme: "bubble",
                            position: "top-center",
                            duration: 3000,
                            fitToScreen: true,
                            fullWidth: false,
                            iconPack: "mdi",
                            icon: "mdi-account-alert-outline",
                            keepOnHover: true,
                        }
                        );
                    }
                    } else if (this.desserts[i].signe == ">") {
                    if (value > value_compared) {
                        this.$toasted.show(this.desserts[i].data + " is > to " + value_compared,
                        {
                            theme: "bubble",
                            position: "top-center",
                            duration: 3000,
                            fitToScreen: true,
                            fullWidth: false,
                            iconPack: "mdi",
                            icon: "mdi-account-alert-outline",
                            keepOnHover: true,
                        }
                        );
                    }
                    } else if (this.desserts[i].signe == "==") {
                        if (value == value_compared) {
                            this.$toasted.show(this.desserts[i].data + " is == to " + value_compared,
                        {
                            theme: "bubble",
                            position: "top-center",
                            duration: 3000,
                            fitToScreen: true,
                            fullWidth: false,
                            iconPack: "mdi",
                            icon: "mdi-account-alert-outline",
                            keepOnHover: true,
                        }
                        );
                        }
                    }
                }
                } catch (error) {
                console.log(error);
                }
            }
        }
    },
  }
</script>