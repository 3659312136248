<template>
  <div>
  <v-card>
    <v-card-title>
      Dashboard
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
    ></v-data-table>
  </v-card>
  </div>
</template>

<script>
import moment from 'moment';
moment.locale('fr');

  export default {
    data () {
      return {
        search: '',
        tag_id: [],
        tag_id_name: [],
        tag_name:[],
        headers: [
          {
            text: 'Tags',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'wifi', value: 'wifi' },
          { text: 'stockage', value: 'stockage' },
          { text: 'durée d\'utilisation', value: 'durée' },
          { text: 'temperature (°C)', value: 'temperature' },
          { text: 'temperature (°F)', value: 'temperaturef' },
          { text: 'accelerometer (g)', value: 'acceléromètre' },
          { text: 'last used', value: 'last_used' },
        ],
        desserts: [],
        id_memory: [],
        interval1: null,
        interval2: null,
        email:'',
      }
    },
    async mounted(){
        await this.getTagAvailable();
        this.CreateTable();
        await this.getStockage();
        await this.getDuration();
        this.interval1 = setInterval(this.getDatafor, 3000);
        this.interval2 = setInterval(() => {
            this.getStockage();
            this.getDuration();
            // Ajoutez autant d'appels de fonctions que nécessaire
        }, 20000);
    },
    methods:{
        CreateTable(){
          for(let i = 0; i < this.tag_id.length; i++){
            this.desserts.push(
              {
              name: this.tag_id_name[i],
              wifi: '',
              durée : 0,
              stockage: 0,
              temperature: 0,
              temperaturef: 0,
              acceléromètre: 0,
              last_used: 0,
            },
            )
          }
        },

      async getDatafor(){
        for(let i = 0; i < this.desserts.length; i++){
          await Promise.all([this.getAllData(i)]);
        }
      },

async getAllData(val_i) {
    const tag_id_name = encodeURIComponent(this.tag_id[val_i]);
    const url = this.$api.getRESTApiUri() + `/all/last_id/${tag_id_name}`;
    return fetch(url)
    .then(res => res.text())
    .then((result) => {
        const data = JSON.parse(result);
        // Gestion des axes
        const axe_x = Array.isArray(data[0].acc_axe_x) && data[0].acc_axe_x.length > 0 ? data[0].acc_axe_x[0] : data[0].acc_axe_x || 0;
        const axe_y = Array.isArray(data[0].acc_axe_y) && data[0].acc_axe_y.length > 0 ? data[0].acc_axe_y[0] : data[0].acc_axe_y || 0;
        const axe_z = Array.isArray(data[0].acc_axe_z) && data[0].acc_axe_z.length > 0 ? data[0].acc_axe_z[0] : data[0].acc_axe_z || 0;
        
        this.desserts[val_i].temperature = data[0]?.temperature / 100 || 0;
        this.desserts[val_i].temperaturef = (data[0]?.temperature / 100 * 1.8 + 32) || 0;
        this.desserts[val_i].acceléromètre = axe_x + '  ,  ' + axe_y + '  ,  ' +  axe_z;
        this.desserts[val_i].last_used = this.formatDate(data[0]?.received_on);

        const b = this.Test(this.id_memory[val_i], data[0]?.id);
        if(b){
            this.desserts[val_i].wifi = 'connected'
        } else {
            this.desserts[val_i].wifi = 'disconnected'
        }
        this.id_memory[val_i] = data[0]?.id || 0;
    })
    .catch((error) => {
        console.log(error)
    });
},


Test(x,y){
return String(x) !== String(y)
},

async getTagAvailable(){
        const user = JSON.parse(localStorage.getItem('user'))
        this.email = user.email
        const email = encodeURIComponent(this.email);
        const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
        return fetch(url)
        .then(res => res.text())
        .then(async (result) => {
            const data = JSON.parse(result)
            this.tag_id = data.tag_attributed
            this.tag_name = data.tag_renamed

            for(let i=0 ; i< this.tag_id.length ; i++){
            if(this.tag_name[i] == "noname"){
                this.tag_id_name.push(this.tag_id[i])
            }else{
                this.tag_id_name.push(this.tag_name[i])
            }
        }
        })
        .catch(error => {
            console.log(error)
        });
},

async getStockage() {
  let results = [];
  for (let i = 0; i < this.tag_id.length; i++) {
    const tag_id = encodeURIComponent(this.tag_id[i]);
    const url = this.$api.getRESTApiUri() + `/list-tag/stockage/${tag_id}`;
    const result = await fetch(url)
    .then(res => res.text())
    .then((result) => {
      const data = JSON.parse(result);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
    results.push(result);
  }
  for (let i = 0; i < results.length; i++) {
    let duration = results[i] ? results[i][0].table_size : '';	
    // Affectation de la durée formatée à la propriété 'durée' de l'objet correspondant
    this.desserts[i].stockage = duration;
  }
},

async getDuration() {
			for (let i = 0; i < this.tag_id.length; i++) {
				const tag_id = this.tag_id[i];
				const url = this.$api.getRESTApiUri() + `/data-transmission-duration/${tag_id}`;
				try {
					const response = await fetch(url);
					const data = await response.json();
					let total_duration = 0;
					data.forEach(item => {
						total_duration += item.duration;
					});
					
					this.desserts[i].durée = this.formatDuration(total_duration)
				} catch (error) {
					console.error("Error fetching sequences:", error);
				}
			}
		},

formatDate(date) {
    return moment(date).format('LL, LT');
},
formatDuration(seconds) {
			const days = Math.floor(seconds / (24 * 3600));
			seconds -= days * 24 * 3600;
			const hours = Math.floor(seconds / 3600);
			seconds -= hours * 3600;
			const minutes = Math.floor(seconds / 60);
			seconds -= minutes * 60;

			return `${days}J ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${Math.floor(seconds).toString().padStart(2, '0')}`;
		},

},
beforeDestroy() {
  clearInterval(this.interval1);
  clearInterval(this.interval2);
},

}
</script>