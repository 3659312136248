<template>
	<div style="padding-left: 100px; padding-right: 100px;">
		<side-bar/>
		<alert/>
		<br>
		<br>
		<tableaumulti/>
		<br>
		<br>
		<alertsetup/>
		<br>
		<br>
	</div>
</template>

<script>
import Tableaumulti from '../components/Dashboard.vue'
import alert from '../components/alert.vue'
import alertsetup from '../components/alert-setup.vue'
import SideBar from '../components/SideBar.vue'
	export default {
		
		components: {
			alert,
			Tableaumulti,
			alertsetup,
			SideBar,
		},
	data(){
		return{
			accessPage: 0,
		}
	},
	methods:{
		
	}
    }
</script>