<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="Name"
    class="elevation-1"
  >
  <template v-slot:item.actions="{ item }">
      <v-icon @click="Delete(item.id)">mdi-delete</v-icon>
      
  </template>
  <v-row justify="center"> 
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Password</span>
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-row>
    <template v-slot:top>
      <v-toolbar flat >
        <v-toolbar-title>My alerts</v-toolbar-title>
        <v-divider class="mx-4" inset vertical ></v-divider>
        <v-spacer></v-spacer>
          <v-btn @click="showForm = true" color="primary">New alert</v-btn>
    <v-dialog v-model="showForm" max-width="1200px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Configure an alert</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12"> 
              <v-select v-model="tag_selected"
            :items="user.tags_owned"
            label="Tags to view"
            chips
            persistent-hint>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4"> 
              <v-select
              label="Raw Data"
              v-model="data_selected"
              :items="data_raw"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="4"> 
              <v-select
              label="Red/IR Data"
              v-model="data_selected"
              :items="data_max">
              </v-select>
            </v-col>
            <v-col cols="12" sm="4"> 
              <v-select
              label="Biometric Data"
              v-model="data_selected"
              :items="data_bio">
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4"> 
              <v-select 
              label="Signe"
              v-model="signe_selected"
              :items="signe"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="4"> 
              <v-text-field
                label="Value"
                value="0.00"
                v-model="value"
              ></v-text-field>
            </v-col>
          </v-row>
          <br><br>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="dateRangeText"
                  label="Your new alert is"
                  prepend-icon="mdi-calendar"
                  readonly
              ></v-text-field>
            </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="addItem" color="primary">Ajouter</v-btn>
              <v-btn @click="showForm = false" color="secondary">Annuler</v-btn>
            </v-card-actions>
            
          </v-row>
        </v-card-text>
        
      </v-card>
    </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    data: () => ({
      showForm: false,
      dialogDelete: false,
      tag:[],
      tag_selected: [],
      data_raw: ['Voltage (V)', 'current (mA)', 'temperature (°C)', 'temperature (°F)', 'accelerometer axe X (g)', 'accelerometer axe Y (g)', 'accelerometer axe Z (g)'],
      data_bio:['heart rate (bpm)', 'systolic blood pressure (Pa)','diastolic blood pressure (Pa)', 'o2 rate (%)'],
      data_max:['Red Led', 'Infrared Led'],
      data_selected: '',
      value: '',
      signe: ['>', '<', '=='],
      signe_selected: [],
      user: {id: 0},
      headers: [
        {text: 'id', value: 'id'},
        {
          text: 'Tags',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'data', value: 'data' },
        { text: 'Sign', value: 'signe' },
        { text: 'value', value: 'value' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
    }),

    computed: {
        dateRangeText () {
          if(this.data_selected == ''){
            this.data_selected = 'no data selected yet'
          }
          return this.tag_selected+ ': ' + this.data_selected + ' ' + this.signe_selected[0] + ' ' + this.value 
        },
      },

    async mounted() {
      this.UpdateDataTable();
    },

    methods: {
      async UpdateDataTable(){
          const userOk = await Promise.all([this.getTagAvailable()])
          if (userOk) {
            const alerts = await Promise.all([this.getAlerts()])
            if (alerts) {
              this.CreateTable();
              this.showForm = false
            }
          } 
      },
      async getTagAvailable(){
        this.user = JSON.parse(localStorage.getItem('user'))
      },
      
      async addItem() {
          if(this.data_selected != '' && this.tag_selected.length > 0 && this.signe_selected.length >0 && this.value != ''){
          if(this.data_selected == 'Voltage (V)'){ this.data_selected = 'adc_v'}
          if(this.data_selected == 'current (mA)'){ this.data_selected = 'adc_i'}
          if(this.data_selected == 'temperature (°C)'){ this.data_selected = 'temperature'}
          if(this.data_selected == 'accelerometer axe X (g)'){ this.data_selected = 'acc_axe_x'}
          if(this.data_selected == 'accelerometer axe Y (g)'){ this.data_selected = 'acc_axe_y'}
          if(this.data_selected == 'accelerometer axe Z (g)'){ this.data_selected = 'acc_axe_z'}
          if(this.data_selected == 'systolic blood pressure (Pa)'){ this.data_selected = 'pa_systolique'}
          if(this.data_selected == 'diastolic blood pressure (Pa)'){ this.data_selected = 'pa_diastolique'}
          if(this.data_selected == 'heart rate (bpm)'){ this.data_selected = 'bpm'}
          if(this.data_selected == 'o2 rate (%)'){ this.data_selected = 'spo2'}
          const alerte = this.tag_selected +'/'+this.data_selected+'/'+this.signe_selected+'/'+this.value
          const user_id = encodeURIComponent(this.user.id);
          const url = this.$api.getRESTApiUri() + `/update-alert`;
          return fetch(url,{
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              alerte : alerte,
              user: user_id
            }),
          })
          .then(response => response.json())
          // Displaying results to console
          .then(this.desserts.push({
              id : 'x',
              name: this.tag_selected,
              data: this.data_selected,
              signe: this.signe_selected,
              value: this.value,
            }));		
        }else{
          window.alert('Please fill all the blank')
        }
    },

      async getAlerts(){
        const user = encodeURIComponent(this.user.id);
				const url = this.$api.getRESTApiUri() + `/user-alert/${user}`;
				return fetch(url)
        .then(res => res.text())
        .then((result) => {
            const data = JSON.parse(result);
            this.alerts = data.alertes;
          })
        .catch((error) => {
            console.log(error)
        });
      },

       CreateTable(){
          for(let i = 0; i < this.alerts.length; i++){
            const char = this.alerts[i].split('/');
            const tag = char[0];
            const data = char[1];
            const signe = char[2];
            const value = char[3];
            this.desserts.push(
              {
              id :i,
              name: tag,
              data: data,
              signe: signe,
              value: value,
            })
          }
      },

      async Delete(index){
        this.alerts.splice(index, 1)
        const user_id = this.user.id;
        const url = this.$api.getRESTApiUri() + `/delete-alert`;
        return fetch(url,{
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              alerte : this.alerts,
              user: user_id
            }),
          })
          .then(response => response.json())
          .then(this.desserts.splice(index, 1))
          // Displaying results to console
      }
    },
  }
</script>